<div class="container" id="first" sHomeStep>
  <div class="scroll-button scroll-down" [class.visible]="scrollButtonVisible" (click)="goToNextStep()">
    <img src="{{ deployUrl }}/assets/cais/scroll-down-arrow.png" alt="scroll-down-arrow" />
  </div>
  <div class="logo"><img src="{{ deployUrl }}/assets/cais/logo.png" alt="logo-cais" /></div>
  <div class="content">
    <div class="title">SMART <span>derivatives</span></div>
    <div class="baseline">Your one-stop-shop for Structured Products</div>
    <button class="login-btn" (click)="onClickLogin()">LOGIN</button>
    <div class="legals">Provided by BNP Paribas Securities Corp</div>
  </div>
</div>
<div class="container" id="second" sHomeStep>
  <div class="scroll-button scroll-up" [class.visible]="scrollButtonVisible" (click)="goToPreviousStep()">
    <img src="{{ deployUrl }}/assets/cais/scroll-up-arrow.png" alt="scroll-up" />
  </div>
  <div class="scroll-button scroll-down" [class.visible]="scrollButtonVisible" (click)="goToNextStep()">
    <img src="{{ deployUrl }}/assets/cais/scroll-down-arrow.png" alt="scroll-down-arrow" />
  </div>
  <div class="content">
    <div class="title">Product</div>
    <div class="baseline"><span>SMART</span> derivatives gives you access to a selection of products</div>
  </div>
  <div class="img-container">
    <img src="{{ deployUrl }}/assets/cais/slide-investment-strategies-1.png" alt="slide-investment-strategies-1" />
  </div>
</div>
<div class="container" id="third" sHomeStep>
  <div class="scroll-button scroll-up" [class.visible]="scrollButtonVisible" (click)="goToPreviousStep()">
    <img src="{{ deployUrl }}/assets/cais/scroll-up-arrow.png" alt="scroll-up" />
  </div>
  <div class="scroll-button scroll-down" [class.visible]="scrollButtonVisible" (click)="goToNextStep()">
    <img src="{{ deployUrl }}/assets/cais/scroll-down-arrow.png" alt="scroll-down-arrow" />
  </div>
  <div class="img-container">
    <img src="{{ deployUrl }}/assets/cais/slide-primary-pricing-1.png" alt="slide-primary-pricing-1" />
  </div>
  <div class="content">
    <div class="title">Primary</div>
    <div class="baseline">An interactive pricing application enabling you to design and price Structured Products</div>
  </div>
</div>
<div class="container" id="fourth" sHomeStep>
  <div class="scroll-button scroll-up" [class.visible]="scrollButtonVisible" (click)="goToPreviousStep()">
    <img src="{{ deployUrl }}/assets/cais/scroll-up-arrow.png" alt="scroll-up" />
  </div>
  <div class="scroll-button scroll-down" [class.visible]="scrollButtonVisible" (click)="goToNextStep()">
    <img src="{{ deployUrl }}/assets/cais/scroll-down-arrow.png" alt="scroll-down-arrow" />
  </div>
  <div class="content">
    <div class="title">Post-Trade Services</div>
    <div class="baseline">Easily manage your trade inventory</div>
  </div>
  <div class="img-container">
    <img src="{{ deployUrl }}/assets/cais/slide-secondary-post-trade-2.png" alt="slide-secondary-post-trade-2" />
  </div>
</div>
<div class="container" id="fifth" sHomeStep>
  <div class="scroll-button scroll-up" [class.visible]="scrollButtonVisible" (click)="goToPreviousStep()">
    <img src="{{ deployUrl }}/assets/cais/scroll-up-arrow.png" alt="scroll-up" />
  </div>
  <div class="content-left">
    <div class="map-container">
      <img src="{{ deployUrl }}/assets/cais/footer-map.png" alt="slide-secondary-post-trade-2" />
    </div>
    <div class="infos-container">
      <div class="title">CAIS Structured Solutions Team</div>
      <div class="phone"><span>Tel: + 1 212-201-2317</span></div>
      <div class="mail"><a href="mailto:spgroup@caisgroup.com ">eMail: SPgroup@caisgroup.com </a></div>
    </div>
  </div>
  <div class="content-right">
    <div class="link-block">
      <a class="login-block" (click)="onClickLogin()">
        <div class="picto">
          <img src="{{ deployUrl }}/assets/cais/footer-key.png" alt="footer-key" />
        </div>
      </a>
      <a
        class="request-block"
        href="mailto:smartderivatives.support@bnpparibas.com?subject=BNP%20Paribas%20Smart%20Derivatives%20Demo%20Request&body=Hi,%20I%20would%20be%20interested%20in%20having%20a%20demo%20of%20Smart%20Derivatives%20platform."
      >
        <div class="picto">
          <img src="{{ deployUrl }}/assets/cais/footer-demo.png" alt="footer-demo" />
        </div>
      </a>
    </div>
  </div>
  <div class="footer-bar">
    <ul>
      <li>
        <span>&copy; 2019 CAIS Capital, LLC. – All rights reserved </span>
      </li>
      <li>
        <a target="_blank" href="https://cib.bnpparibas.com/about/privacy-policy_a-38-60.html"
          >Data Protection Notice</a
        >
      </li>
      <li>
        <a target="_blank" href="http://cib.bnpparibas.com/Miscellaneous/Cookies-Policy/page.aspx/215">Cookie Policy</a>
      </li>
      <li><a href="https://globalmarkets.bnpparibas.com/gm/commons/Disclaimer.html">Disclaimer</a></li>
      <li><a href="http://cib.bnpparibas.com/Miscellaneous/Regulation">Regulation</a></li>
      <li><a href="http://cib.bnpparibas.com/Miscellaneous/Privacy-policy/page.aspx/222">Privacy Policy</a></li>
    </ul>
  </div>
</div>
