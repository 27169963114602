interface SdppError {
  httpCode: number;
  errorCode: string;
  message: string;
}

export const SdppErrors: SdppError[] = [
  { httpCode: 404, errorCode: 'AppNotFound', message: 'The requested application does not exist.' },
  { httpCode: 404, errorCode: 'AppNotInPortal', message: 'The requested application does not exist.' },
  { httpCode: 403, errorCode: 'AppForbidden', message: 'You do not have access to the requested application.' },
  { httpCode: 403, errorCode: 'WhitelabelForbidden', message: 'You do not have access to the requested portal.' },

  {
    httpCode: 500,
    errorCode: 'AppCdnNameMissing',
    message: `The requested application has encountered an internal error.
  If the error persists, please contact support team by clicking on the "Contact" link below.`
  },
  {
    httpCode: 500,
    errorCode: 'AppVersionMissing',
    message: `The requested application has encountered an internal error.
  If the error persists, please contact support team by clicking on the "Contact" link below.`
  },
  {
    httpCode: 500,
    errorCode: 'CannotParseIndex',
    message: `The requested application has encountered an internal error.
    If the error persists, please contact support team by clicking on the "Contact" link below.`
  },
  {
    httpCode: 500,
    errorCode: 'Unexpected',
    message: `The requested application has encountered an internal error.
    If the error persists, please contact support team by clicking on the "Contact" link below.`
  }
];
