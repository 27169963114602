<p-dialog [(visible)]="showKnowMore" [showHeader]="false" modal="true">
  <video width="700" height="394" *ngIf="showKnowMore" controls autoplay id="intro-video">
    <source src="{{ deployUrl }}/assets/smartd/LoginSupport.mp4" type="video/mp4" />
    Your browser doesn't support HTML5 video.
  </video>
</p-dialog>

<p-dialog
  [(visible)]="showDisclaimer"
  header="Disclaimer"
  i18n-header
  [modal]="true"
  [resizable]="false"
  styleClass="large footer-pop-in autoid-disclaimer-dialog"
>
  <s-home-disclaimer></s-home-disclaimer>

  <p-footer>
    <button
      pButton
      type="button"
      class="p-button-secondary autoid-dialog-close-disclaimer-button"
      label="Close"
      i18n-label
      (click)="showDisclaimer = false"
    ></button>
  </p-footer>
</p-dialog>

<p-dialog
  [(visible)]="showSecurityTips"
  header="Security Tips"
  i18n-header
  [modal]="true"
  [resizable]="false"
  styleClass="large footer-pop-in autoid-security-tips-dialog"
>
  <!-- TODO This content should be sent from an Sdpp Web public API (public/api/sdpp/user/GetUserfooterInformationcontent) -->
  <div class="sd-disclaimer-text">
    <p>
      In order to ensure online safety, it is recommended that this informational helppage is periodically reviewed in
      order to remain abreast of any evolving securitythreats or changes in security procedures.
    </p>
    <h2>Keeping Private Information Safe</h2>
    <hr />
    <h3 class="nospacing">Usernames, Logins and Passwords</h3>
    <p>
      It is of utmost importance that login credentials are secured as these constitutethe entry-points to BNP Paribas
      platforms. The following guidelines can assist inkeeping your private information safe:
    </p>
    <ul class="securitytips">
      <li>Avoid re-using the same usernames and passwords that you use for other website logins</li>
      <li>Do not use information that can be easily deduced</li>
      <li>
        Even though your user ID (usually the email address) itself is not confidential, do not write it down on
        anything that can be easily found by a malicious person.
      </li>
      <li>
        Never write down or reveal your password, SecurID Serial or pin number to anyone, including BNP Paribas Support
        Teams
      </li>
      <li>Change your password periodically</li>
      <li>Ensure that you are not being observed when entering your password</li>
      <li>
        Periodically check your keyboard and computer to ensure that no key loggers (devices that record keystrokes) are
        maliciously connected
      </li>
    </ul>
    <p>
      Many browsers contain auto-complete functionality. Whilst this saves time for theuser, it also allows unauthorised
      individuals to log into your account if your computerremains unlocked and unattended. It is recommended that you
      disable your web browser’sauto-complete functionality.
    </p>
    <p>
      Whatever the circumstances, <strong>never communicate your PIN/secret code to anyone</strong>(including support
      teams) and make sure nobody knows it.
    </p>
    <p>
      Last but not least, if you lose or believe you could have lost your token, pleasecontact the Help Desk as soon
      <strong>as possible so that we can disable your token.</strong>
    </p>
    <h3>Authentication Devices</h3>
    <p>
      Should you be issued with authentication tokens or one-time passwords sent to amobile device, please ensure that
      these devices are kept secure at all times.
    </p>
    <p>
      Do not communicate by phone or to an unknown email address the serial number writtenbehind the token, even if
      claiming to be from a support team,
      <strong>unless yourself have contacted a relevant support team earlier</strong> for a PIN reset or
      cardsynchronization issue. In the later case, it is OK to communicate the serial numberto Help Desk for action.
    </p>
    <p>In any case, <strong>do not paste or write anything on the SecurID token!</strong></p>
    <h3>Logging Out</h3>
    <p>
      If you are away from your computer for an extended period of time, please ensurethat you log out of all running
      applications. It is highly recommended that browserapplications are closed fully after using any BNP Paribas
      platforms.
    </p>
    <h3>Viruses and Spyware</h3>
    <p>
      Anti-Virus software, anti-spyware software, and personal firewalls should be installedand continually kept active
      on your computer. Security patches and virus definitionsshould be periodically installed and updated in order to
      ensure that any bugs andsecurity loopholes are closed.
    </p>
    <h3>Your Personal Information</h3>
    <p>Please keep your relationship manager updated with accurate details of your personalinformation.</p>
    <h2>Avoiding Fraud</h2>
    <hr />
    <h3 class="nospacing">Verifying Website</h3>
    <p>
      Navigating to the Website should always be done through known hyperlinks. Pleaseread the address bar / URL
      carefully and always ensure that it is correct.
    </p>
    <p>
      Another method of verifying the authenticity of a Web Site is to check the digitalcertificate for websites that
      begin with “https”. Certification Authorities (suchas Verisign or Geotrust) are trusted third party issuers of
      digital certificateswhich verify that the website URL is a genuine site of the company or business inquestion.
      Click on the padlock next to the URL to see details of the CertificationAuthority:
    </p>
    <h3>Trusted Computers</h3>
    <p>Do not conduct any transactions through public or shared computers.</p>
    <h3>Fraudulent Emails and Websites</h3>
    <p>
      Remain vigilant for suspicious emails and websites that attempt to use deceit inorder to reveal sensitive
      information. BNP Paribas will never ask you for privateinformation by email and will not send e-mails with
      embedded hyperlinks to transactionalwebsites.
    </p>
    <p>
      Also, please be aware that in some email applications such as Microsoft Outlook,a text hyperlink may be displayed
      but actually clicking on the hyperlink may directyou to another website. This is known as phishing. Phishing
      websites are designedto look identical to genuine websites. Additionally, some emails may contain imagefiles that
      appear to look like text. Hovering over the image and clicking may leadyou to a phishing website. Ensure that the
      guidelines for verifying BNP Paribaswebsites (above) are followed.
    </p>
    <h3>Other security precaution</h3>
    <p>
      Remove file and printer sharing in their computers, especially when they have internetaccess via cable modems,
      broadband connections or similar set-ups.<br />Make regular backup of critical data.<br />Consider the use of
      encryption technology to protect highly sensitive data.<br />Do not install software or run programs of unknown
      origin.<br />Delete junk or chain emails.<br />Do not open email attachments from strangers.<br />Please check
      your quotation and/or transaction activity frequently and report any discrepancy to BNP Paribas
    </p>
    <h2>We Are Here To Help</h2>
    <hr />
    <p>
      Should you suspect any unauthorised access or have any outstanding queries regardingInformation Security, please
      promptly contact your relationship manager or supportteam.
    </p>
    <div style="width: 330px; margin: 40px auto 0 auto">
      <div class="contactDetailsLabel"><strong>US</strong></div>
      <div class="contactDetailsValue">Phone : +1 212 471 7981</div>
      <div class="contactDetailsValue">
        Email : <a href="mailto:smart.americas@us.bnpparibas.com">smart.americas@us.bnpparibas.com</a>
      </div>
      <hr class="dottedLine" style="clear: both" />
      <div class="contactDetailsLabel"><strong>Europe</strong></div>
      <div class="contactDetailsValue">Phone : +33 1 55 77 98 80</div>
      <div class="contactDetailsValue">
        Email : <a href="mailto:smartderivatives.support@bnpparibas.com">smartderivatives.support@bnpparibas.com</a>
      </div>
      <hr class="dottedLine" style="clear: both" />
      <div class="contactDetailsLabel"><strong>Asia Pacific Support</strong></div>
      <div class="contactDetailsValue">Phone : +852 2825 1752</div>
      <div class="contactDetailsValue">
        Email :
        <a href="mailto:dl.apac.gecd.smartderivatives.support@asia.bnpparibas.com"
          >dl.apac.gecd.smartderivatives.support@asia.bnpparibas.com</a
        >
      </div>
    </div>
  </div>

  <p-footer>
    <button
      pButton
      type="button"
      class="p-button-secondary autoid-dialog-close-disclaimer-button"
      label="Close"
      i18n-label
      (click)="showSecurityTips = false"
    ></button>
  </p-footer>
</p-dialog>

<header>
  <div class="header__logo">
    <img src="{{ deployUrl }}/assets/smartd/logo.svg" alt="Logo" />
  </div>

  <a class="header__login" (click)="onClickLogin()">
    <img src="{{ deployUrl }}/assets/smartd/login-with-text.svg" alt="Login" />
  </a>
</header>

<main>
  <div class="main__title"><b>SMART</b> derivatives</div>

  <p class="main__motto">
    Your one-stop-shop to a comprehensive
    <br />
    structured products platform
  </p>

  <button class="main__login" (click)="onClickLogin()">
    <img src="{{ deployUrl }}/assets/smartd/login.svg" alt="Login" /><span>LOGIN</span>
  </button>

  <button class="main__know" (click)="onClickKnowMore()">
    <img src="{{ deployUrl }}/assets/smartd/know-more.svg" alt="Know more" /><span>KNOW MORE</span>
  </button>

  <div class="process">
    <div class="process__investment">
      <div class="process__content">
        <img
          src="{{ deployUrl }}/assets/smartd/investment-strategies.svg"
          alt="{{ investmentStrategiesTitle.partOne }} {{ investmentStrategiesTitle.partTwo }}"
        />
        <br />
        {{ investmentStrategiesTitle.partOne }}
        <br />
        {{ investmentStrategiesTitle.partTwo }}
      </div>
    </div>

    <div class="process__pricing">
      <div class="process__content">
        <img src="{{ deployUrl }}/assets/smartd/primary-pricing-and-trading.svg" alt="Primary Pricing & Trading" />
        <br />
        Primary Pricing
        <br />
        &amp; Trading
      </div>
    </div>

    <div class="process__services">
      <div class="process__content">
        <img src="{{ deployUrl }}/assets/smartd/post-trade-services.svg" alt="Post-Trade Services" />
        <br />
        Post-Trade
        <br />
        Services
      </div>
    </div>
  </div>

  <div class="sd-user-info" style="color: black"></div>
</main>

<footer>
  <div class="footer__map">
    <img src="{{ deployUrl }}/assets/smartd/map.svg" alt="Map" />
  </div>

  <div class="footer__location">
    <div class="footer__country">America</div>
    <div class="footer__phone">
      <img src="{{ deployUrl }}/assets/smartd/phone.svg" alt="Phone" />
      <span>+1 212 471 7981</span>
    </div>
    <div class="footer__email">
      <img src="{{ deployUrl }}/assets/smartd/email.svg" alt="Email" />
      <a href="mailto:smart.americas@us.bnpparibas.com"> smart.americas@us.bnpparibas.com </a>
    </div>
  </div>

  <div class="footer__location">
    <div class="footer__country">Europe, Middle East &amp; Africa</div>
    <div class="footer__phone">
      <img src="{{ deployUrl }}/assets/smartd/phone.svg" alt="Phone" />
      +33 1 55 77 98 80
    </div>
    <div class="footer__email">
      <img src="{{ deployUrl }}/assets/smartd/email.svg" alt="Email" />
      <a href="mailto:smartderivatives.support@bnpparibas.com"> smartderivatives.support@bnpparibas.com </a>
    </div>
  </div>

  <div class="footer__location">
    <div class="footer__country">Asia Pacific</div>
    <div class="footer__phone">
      <img src="{{ deployUrl }}/assets/smartd/phone.svg" alt="Phone" />
      +852 2825 1752
    </div>
    <div class="footer__email">
      <img src="{{ deployUrl }}/assets/smartd/email.svg" alt="Email" />
      <a href="mailto:dl.apac.smartderivatives@asia.bnpparibas.com"> dl.apac.smartderivatives@asia.bnpparibas.com </a>
    </div>
  </div>
  <div class="footer__copyright">
    <p>
      ©<span data-bind="text: copyrightYear">2019</span> BNP Paribas - All rights reserved |
      <a href="https://cib.bnpparibas.com/about/privacy-policy_a-38-60.html" target="_blank">Data Protection Notice</a>
      |
      <a href="http://cib.bnpparibas.com/Miscellaneous/Cookies-Policy/page.aspx/215" target="_blank">Cookies Policy</a>
      | <a (click)="onClickSecurityTips()">Security Tips</a> | <a (click)="onClickDisclaimer()">Disclaimer</a> |
      <img src="{{ deployUrl }}/assets/smartd/email.svg" alt="Email" /><a
        href="mailto:smartderivatives.support@bnpparibas.com"
        >Contact</a
      >
    </p>
  </div>
</footer>
