import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Observable, zip } from 'rxjs';

import { Logger, LoggingService } from '@sdpp-web/logger';
import { TelemetryService } from '@sdpp-web/telemetry';

import { environment } from '@environment';

import { TermsOfUseRequestService } from './services/terms-of-use-request.service';
import { TermsOfUseMessageService } from './services/terms-of-use.message.service';

@Component({
  selector: 's-home-terms-of-use',
  styleUrls: ['./terms-of-use.component.scss'],
  templateUrl: './terms-of-use.component.html'
})
export class TermsOfUseComponent implements OnInit {
  @ViewChild('content')
  public content: ElementRef;

  public readonly isDevEnvironment: boolean = environment.isDevEnvironment;

  public termsContent$: Observable<string>;

  public hasScrolledToBottom: boolean;
  public tooltipMessage: string;

  private readonly _logger: Logger;

  private readonly MAX_REMAINING_SCROLL_PX: number = 20;

  constructor(
    private readonly _requestService: TermsOfUseRequestService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _messageService: TermsOfUseMessageService,
    private readonly _telemetryService: TelemetryService,
    loggingService: LoggingService
  ) {
    this._logger = loggingService.getLogger('TermsOfUseComponent');
  }

  public ngOnInit(): void {
    this.termsContent$ = this._requestService.loadContent();
    this.termsContent$.subscribe(() => {
      setTimeout(() => {
        this._telemetryService.message({
          message: `displaying terms of use`,
          data: null
        });
        this.hasScrolledToBottom = false;
        this.checkScroll();
      }, 0);
    });
  }

  public onClickAccept(): void {
    this.checkScroll();
    if (this.hasScrolledToBottom) {
      this.sendAccept();
    }
  }

  public onClickDecline(): void {
    this._telemetryService.message({
      message: `user declined terms of use`,
      data: null
    });
    this._logger.info(`user declined terms of use. Reloading terms of use.`);
    location.reload();
  }

  public onScrollContent(): void {
    this.checkScroll();
  }

  private checkScroll(): void {
    const element: HTMLElement = this.content.nativeElement;
    if (!this.hasScrolledToBottom) {
      this.hasScrolledToBottom =
        element.scrollHeight - element.scrollTop - element.clientHeight <= this.MAX_REMAINING_SCROLL_PX;
    }
    this.tooltipMessage = this.hasScrolledToBottom ? null : this._messageService.PLEASE_SCROLL;
  }

  private sendAccept(): void {
    this._telemetryService.message({
      message: `user accepted terms of use`,
      data: null
    });

    zip(this._requestService.sendAccept(), this._activatedRoute.queryParams).subscribe(
      ([defaultRedirectUrl, params]: [string, Params]) => {
        const url: string = params[environment.termsOfUseTargetUrlParam] || defaultRedirectUrl;
        this._logger.info(`user accepted terms of use. Redirecting to ${url}`);
        location.href = url;
      }
    );
  }
}
