<s-header appcode="home" show-application-menu="false" [attr.show-themes-menu]="isDevEnvironment"></s-header>
<div class="terms-page">
  <h1 i18n>Terms and Conditions</h1>
  <div #content class="terms-content" (scroll)="onScrollContent()">
    <div *ngIf="termsContent$ | async as termsContent" [innerHTML]="termsContent | safeHtml"></div>
  </div>
  <p-footer>
    <p-button styleClass="p-button-danger" (click)="onClickDecline()" i18n-label label="I do not accept"></p-button>
    <p-button
      styleClass="p-button-success"
      (click)="onClickAccept()"
      i18n-label
      label="I accept"
      [disabled]="!hasScrolledToBottom"
      [pTooltip]="tooltipMessage"
      tooltipPosition="top"
      tooltipStyleClass="conditions-tooltip"
    ></p-button>
  </p-footer>
</div>
<s-footer appcode="home"></s-footer>
