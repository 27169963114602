import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoggingModule } from '@sdpp-web/logger';
import { TelemetryModule } from '@sdpp-web/telemetry';
import { USER_IMPERSONATION_PROVIDERS } from '@sdpp-web/user';

import { environment } from '@environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoggingModule.forRoot(environment.loggingConfiguration),
    TelemetryModule.forRoot(environment.telemetryLoggerId)
  ],
  providers: [USER_IMPERSONATION_PROVIDERS(environment.userImpersonationConfiguration)]
})
export class AppModule {}
