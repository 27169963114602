export function getSsoUrl(): string {
  const links: NodeListOf<HTMLMetaElement> = document.querySelectorAll(`meta[name=sso-url]`);
  if (links.length > 0) {
    return links[0].content;
  }

  return null;
}

/**
 * https://davidwalsh.name/query-string-javascript
 */
export function getCurrentLocationUrlParameter(name: string): string {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`, 'i');
  const results = regex.exec(location.search);

  return results && decodeURIComponent(results[1].replace(/\+/g, ' '));
}
/**
 * http://stackoverflow.com/a/10997390/11236
 */
export function updateURLParameter(url: string, param: string, paramVal: string): string {
  let anchor: string;
  let newAdditionalURL = '';
  let tempArray = url.split('?');
  let baseURL = tempArray[0];
  let additionalURL = tempArray[1];
  let temp = '';

  if (additionalURL) {
    const tmpAnchor = additionalURL.split('#');
    const params = tmpAnchor[0];
    anchor = tmpAnchor[1];
    if (anchor) {
      additionalURL = params;
    }

    tempArray = additionalURL.split('&');

    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split('=')[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = '&';
      }
    }
  } else {
    const tmpAnchor = baseURL.split('#');
    const params = tmpAnchor[0];
    anchor = tmpAnchor[1];

    if (params) {
      baseURL = params;
    }
  }

  if (anchor) {
    paramVal += `#${anchor}`;
  }

  const rows_txt = `${temp}${param}=${paramVal}`;

  return `${baseURL}?${newAdditionalURL}${rows_txt}`;
}

export function cleanupHomepageTarget(target: string): string {
  const regex = new RegExp('^-SM-(.*)', 'i');
  const match = regex.exec(target);
  if (match) {
    return match[1];
  }

  return target;
}
