import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

import {
  BrioHomeComponent,
  DisclaimerComponent,
  EnqdcHomeComponent,
  HomeComponent,
  SmartdBancageneraliHomeComponent,
  SmartdCaisHomeComponent,
  SmartdHomeComponent
} from './components';
import { StepDirective } from './directives';

@NgModule({
  declarations: [
    StepDirective,
    BrioHomeComponent,
    DisclaimerComponent,
    EnqdcHomeComponent,
    HomeComponent,
    SmartdHomeComponent,
    SmartdCaisHomeComponent,
    SmartdBancageneraliHomeComponent
  ],
  imports: [CommonModule, ButtonModule, DialogModule]
})
export class HomeModule {}
