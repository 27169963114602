<div class="page-wrapper">
  <header>
    <div class="logo"></div>

    <div class="header-baseline">The bank for a changing world</div>
  </header>
  <main>
    <div class="image-and-buttons-wrapper">
      <div class="image-wrapper">
        <div class="logo-neo"></div>
      </div>

      <div class="buttons-wrapper">
        <button class="login" (click)="onClickLogin()">LOG IN</button>
        <div class="login-label">Please sign in to continue</div>

        <div id="knowmore" [ngClass]="{ collapsed: contactCollapsed }">
          <button id="morebutton" (click)="onClickContact()">CONTACT US</button>

          <p><i>America</i> <span>+1 212 471 7981</span></p>
          <p>
            <i>Email us</i
            ><span><a href="mailto:SMART.AMERICAS@us.bnpparibas.com">SMART.AMERICAS@us.bnpparibas.com</a></span>
          </p>
        </div>
      </div>
    </div>
  </main>
  <footer>© BNP Paribas - 2019</footer>
</div>
