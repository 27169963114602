<div>
  <p>
    Products described on this web-site are of a specialist nature and should only be traded by investors who are
    particularly knowledgeable in these investment products. Investment in these products involves high degree of risk.
    User of the web-site, by its purchase of these products, will be deemed to have understood (after taking
    professional advice where appropriate) the risks involved in these products.
  </p>
  <p>This site contains both indicative and firm price information.</p>
  <p>Prices shown on the web-site that are labelled as "firm" are tradable prices at the quantity indicated.</p>
  <p>
    All other prices are indicative information and BNP Paribas gives no assurance that any transaction will be entered
    into on the basis of these indicative terms.
  </p>
  <p>
    Information on this site does not constitute a valuation or an offer or solicitation to buy or sell any securities.
  </p>
  <p>
    Information on this site is not intended to provide the sole basis for any evaluation by you of the security
    described herein and you agree that the merits or suitability of any such security to your particular situation will
    be independently determined by you, upon the advice from such advisers as you deem necessary, including
    consideration of the legal, tax, accounting, regulatory, financial and other related aspects thereof and you should
    not rely on BNP Paribas for this.
  </p>
  <p>
    Any price quotations (indicative or firm), disclosure materials, models or analyses provided to you have been
    prepared on assumption and parameters that reflect our good faith judgement or selection and therefore no guarantee
    is given on to the accuracy, completeness or reasonableness of any such quotation, disclosures or analyses.
  </p>
  <p></p>
  <p>
    No representation or warranty is made that any indicative performance or return indicated will be achieved in the
    future.
  </p>
  <p>
    BNP Paribas and its Affiliates may (or may in the future) be long or short of or may have a financial interest in
    any securities described herein.
  </p>
  <p>
    Information on this site is provided to you on a strictly confidential basis and you agree that it may not be
    copied, reproduced or otherwise distributed by you without our prior written consent.
  </p>
  <p>
    BNP Paribas London Branch <br />
    10 Harewood Avenue, London NW1 6AA; tel: +44 20 7595 2000; fax: +44 20 7595 2555; www.bnpparibas.com. <br />
    Incorporated in France with Limited Liability. Registered Office: 16 boulevard des Italiens, 75009 Paris, France.
    662 042 449 RCS Paris. <br />
    BNP Paribas London Branch is lead supervised by the European Central Bank (ECB) and the Autorité de Contrôle
    Prudentiel et de Résolution (ACPR). BNP Paribas London Branch is authorised by the ECB, the ACPR and the Prudential
    Regulation Authority and subject to limited regulation by the Financial Conduct Authority and Prudential Regulation
    Authority. Details about the extent of our authorisation and regulation by the Prudential Regulation Authority, and
    regulation by the Financial Conduct Authority are available from us on request. BNP Paribas London Branch is
    registered in England and Wales under no. FC13447.
  </p>
</div>
