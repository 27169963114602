import { Component } from '@angular/core';

import { MetaInfoService, MetaName, Whitelabel } from '@sdpp-web/portal';

import { HomeBaseComponent } from '../home-base.component';

@Component({
  selector: 's-home-smartd',
  styleUrls: ['./smartd.component.css'],
  templateUrl: './smartd.component.html'
})
export class SmartdHomeComponent extends HomeBaseComponent {
  public showKnowMore: boolean;
  public showDisclaimer: boolean;
  public showSecurityTips: boolean;
  public investmentStrategiesTitle: { partOne: string; partTwo: string } = {
    partOne: 'Investment',
    partTwo: 'Strategies'
  };

  constructor(metaService: MetaInfoService) {
    super(metaService);

    const whiteLabel = metaService.getMeta(MetaName.Whitelabel);
    if (whiteLabel === Whitelabel.SmartUs || whiteLabel === Whitelabel.SeFlow) {
      this.investmentStrategiesTitle = {
        partOne: 'Product',
        partTwo: 'Range'
      };
    }
  }

  public onClickKnowMore(): void {
    this.showKnowMore = true;
  }

  public onClickDisclaimer(): void {
    this.showDisclaimer = true;
  }

  public onClickSecurityTips(): void {
    this.showSecurityTips = true;
  }
}
