import { Component } from '@angular/core';

import { MetaInfoService, MetaName, Portal, Whitelabel } from '@sdpp-web/portal';
import { TelemetryService } from '@sdpp-web/telemetry';

@Component({
  selector: 's-home-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html'
})
export class HomeComponent {
  public readonly portal: Portal;
  public readonly Portal: typeof Portal = Portal;

  public readonly whitelabel: Whitelabel;
  public readonly Whitelabel: typeof Whitelabel = Whitelabel;

  constructor(metaService: MetaInfoService, telemetryService: TelemetryService) {
    this.portal = metaService.getMeta(MetaName.Portal) as Portal;
    this.whitelabel = metaService.getMeta(MetaName.Whitelabel) as Whitelabel;
    telemetryService.message({
      message: `Homepage reached`,
      data: { portal: this.portal, whitelabel: this.whitelabel }
    });
  }
}
