import { Component, ElementRef, HostListener, QueryList, ViewChildren } from '@angular/core';

import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import { MetaInfoService } from '@sdpp-web/portal';

import { StepDirective } from '../../directives';
import { HomeBaseComponent } from '../home-base.component';

@Component({
  selector: 's-home-smartd-cais',
  styleUrls: ['./smartd-cais.component.scss'],
  templateUrl: './smartd-cais.component.html'
})
export class SmartdCaisHomeComponent extends HomeBaseComponent {
  @ViewChildren(StepDirective, {
    read: ElementRef
  })
  public readonly steps: QueryList<ElementRef>;

  public current: number = 0;
  public scrollButtonVisible: boolean = false;

  private _displayScrollButtonTimeout: number;
  private _isPendingScroll: boolean = false;

  private readonly _scrollDuration: number = 1000;

  constructor(metaService: MetaInfoService) {
    super(metaService);
  }

  @HostListener('mousemove', ['$event'])
  public onMouseMove(_event: MouseEvent): void {
    clearTimeout(this._displayScrollButtonTimeout);
    this._displayScrollButtonTimeout = window.setTimeout(() => {
      this.toggleScrollButton(false);
    }, 5000);
    this.toggleScrollButton(true);
  }

  @HostListener('mousewheel', ['$event'])
  @HostListener('wheel', ['$event'])
  @HostListener('DOMMouseScroll', ['$event'])
  public onMouseWheel(args: WheelEvent): void {
    args.preventDefault();

    if (!this._isPendingScroll) {
      this._isPendingScroll = true;

      if (args.deltaY > 0) {
        this.goToNextStep();
      }

      if (args.deltaY < 0) {
        this.goToPreviousStep();
      }

      setTimeout(() => {
        this._isPendingScroll = false;
      }, this._scrollDuration);
    }
  }

  public goToNextStep(): void {
    if (this.current < this.steps.length - 1) {
      ++this.current;
      const targetStep = this.steps.find((_item, index) => index === this.current);

      this.goTo(targetStep);
    }
  }

  public goToPreviousStep(): void {
    if (this.current > 0) {
      --this.current;
      const targetStep = this.steps.find((_item, index) => index === this.current);

      this.goTo(targetStep);
    }
  }

  private goTo(target: ElementRef): void {
    scrollIntoView(target.nativeElement, {
      block: 'start',
      scrollMode: 'always',
      behavior: 'smooth',
      duration: this._scrollDuration
    });
  }

  private toggleScrollButton(show: boolean): void {
    this.scrollButtonVisible = show;
  }
}
