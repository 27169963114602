import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

import { SafeHtmlPipe } from '../../core/pipes/safe-html.pipe';
import { TermsOfUseComponent } from './terms-of-use.component';

@NgModule({
  declarations: [TermsOfUseComponent, SafeHtmlPipe],
  exports: [TermsOfUseComponent],
  imports: [CommonModule, HttpClientModule, ButtonModule, TooltipModule, BrowserAnimationsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TermsOfUseModule {}
