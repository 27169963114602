import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from './screens/error/error.component';
import { ErrorModule } from './screens/error/error.module';
import { HomeComponent } from './screens/home/components/home.component';
import { HomeModule } from './screens/home/home.module';
import { TermsOfUseComponent } from './screens/terms-of-use/terms-of-use.component';
import { TermsOfUseModule } from './screens/terms-of-use/terms-of-use.module';

const routes: Routes = [
  { path: 'error', component: ErrorComponent },
  { path: 'termsofuse', component: TermsOfUseComponent },
  { path: '', component: HomeComponent }
];

@NgModule({
  exports: [RouterModule],
  imports: [ErrorModule, TermsOfUseModule, HomeModule, RouterModule.forRoot(routes)]
})
export class AppRoutingModule {}
