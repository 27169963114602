<div class="sd-header">
  <div class="sd-header-content">
    <div class="banner">
      <div class="app-name autoid-portal-name">
        <h1>MY SUPPORT SERVICES</h1>
      </div>

      <div class="dev-environment">
        <span *ngIf="isDevEnvironment">(DEV)</span>
      </div>

      <div *ngIf="!!impersonationUserUid" class="user-impersonation">
        <span>{{ impersonationUserUid }}</span>
        <a href="#" pTooltip="Clear impersonation" i18n-pTooltip (click)="unsetImpersonation()">
          <span class="pi pi-times"></span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="sd-menu">
  <div class="burger_menu_cont">
    <div class="hamburger">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </div>
  </div>
  <div class="app_name_cont">
    <span>ASSISTANCE</span>
  </div>
</div>
<div>
  <div class="sd-body grid">
    <div class="encountered-issue-badge col-fixed grid flex-column">
      <span class="issue-title">Encountered issue:</span>
      <div class="col">{{ message }}</div>
      <div class="col button-container">
        <button
          type="button"
          class="p-button no-severity homepage-button"
          label="Homepage"
          pButton
          (click)="navigateToHomepage()"
        ></button>
      </div>
      <div class="col align-end">Code: {{ errorCode | uppercase }}</div>
      <div class="col align-end">IncidentId: {{ correlationId }}</div>
    </div>
    <div class="col error-code-container grid flex-column align-content-center vertical-container">
      <div class="error-code-message col">
        <span>{{ httpCode }}</span> <i class="sdi sdi-warning-round-full"></i>
      </div>
      <div class="error-code-detail col">Unreachable page</div>
    </div>
  </div>
</div>
<div class="sd-footer">
  <div class="sd-footer-content">
    <ul class="link-list">
      <li class="autoid-footer-item">
        <a
          href="mailto:{{ mailSupport }}?subject=An error occurred when accessing application {{ applicationUrl }}&body=
          Error code%20%3A {{ errorCode }}%0D%0A
          CorrelationId%20%3A {{ correlationId }}%0D%0A
          Application%20%3A {{ applicationUrl }}%0D%0A
          Portal%20%3A {{ portalUrl }}"
          class="autoid-footer-link"
        >
          Contact</a
        >
      </li>
    </ul>
  </div>
</div>
