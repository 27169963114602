import { Component } from '@angular/core';

@Component({
  selector: 's-home-root',
  styleUrls: ['./app.component.css'],
  templateUrl: './app.component.html'
})
export class AppComponent {
  public title: string = 'home';
}
