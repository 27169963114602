<ng-container [ngSwitch]="portal">
  <s-home-brio *ngSwitchCase="Portal.Brio"></s-home-brio>
  <s-home-enqdc *ngSwitchCase="Portal.Enqdc"></s-home-enqdc>
  <ng-container *ngSwitchDefault>
    <ng-container [ngSwitch]="whitelabel">
      <s-home-smartd-bancagenerali *ngSwitchCase="Whitelabel.Bancagenerali"></s-home-smartd-bancagenerali>
      <s-home-smartd-cais *ngSwitchCase="Whitelabel.Cais"></s-home-smartd-cais>
      <s-home-smartd *ngSwitchDefault> </s-home-smartd>
    </ng-container>
  </ng-container>
</ng-container>
