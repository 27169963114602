import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { MetaInfoService } from '@sdpp-web/portal';

import { HomeBaseComponent } from '../home-base.component';

@Component({
  selector: 's-home-brio',
  styleUrls: ['./brio.component.scss'],
  templateUrl: './brio.component.html'
})
export class BrioHomeComponent extends HomeBaseComponent {
  public contactCollapsed: boolean;

  constructor(titleService: Title, metaService: MetaInfoService) {
    super(metaService);
    titleService.setTitle('Brio, Think, Invest');
  }

  public onClickContact(): void {
    this.contactCollapsed = !this.contactCollapsed;
  }
}
