import { Component } from '@angular/core';

import { MetaInfoService } from '@sdpp-web/portal';

import { HomeBaseComponent } from '../home-base.component';

@Component({
  selector: 's-home-smartd-bancagenerali',
  styleUrls: ['./smartd-bancagenerali.component.scss'],
  templateUrl: './smartd-bancagenerali.component.html'
})
export class SmartdBancageneraliHomeComponent extends HomeBaseComponent {
  constructor(metaService: MetaInfoService) {
    super(metaService);
  }
}
