import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { MetaInfoService } from '@sdpp-web/portal';

import { HomeBaseComponent } from '../home-base.component';

@Component({
  selector: 's-home-enqdc',
  styleUrls: ['../brio/brio.component.scss', './enqdc.component.scss'],
  templateUrl: './enqdc.component.html'
})
export class EnqdcHomeComponent extends HomeBaseComponent {
  public contactCollapsed: boolean;

  constructor(titleService: Title, metaService: MetaInfoService) {
    super(metaService);
    titleService.setTitle('Neo, eNQDC');
  }

  public onClickContact(): void {
    this.contactCollapsed = !this.contactCollapsed;
  }
}
