import { MetaInfoService, MetaName } from '@sdpp-web/portal';

import {
  cleanupHomepageTarget,
  getCurrentLocationUrlParameter,
  getSsoUrl,
  updateURLParameter
} from '../models/url-util';

const SSO_URL_TARGET = 'TARGET';
const HOMEPAGE_URL_TARGET = 'TARGET';

export class HomeBaseComponent {
  public readonly deployUrl: string;

  constructor(metaService: MetaInfoService) {
    this.deployUrl = metaService.getMeta(MetaName.DeployUrl);
  }

  public onClickLogin(): void {
    const ssoUrl = getSsoUrl();
    let redirectUrl = ssoUrl;

    try {
      let homepageTarget = getCurrentLocationUrlParameter(HOMEPAGE_URL_TARGET);
      if (homepageTarget) {
        homepageTarget = cleanupHomepageTarget(homepageTarget);
        redirectUrl = updateURLParameter(ssoUrl, SSO_URL_TARGET, homepageTarget);
      }
    } catch {}

    window.location.href = redirectUrl;
  }
}
