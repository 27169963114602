import { Component } from '@angular/core';

import { Observable } from 'rxjs';

@Component({
  selector: 's-home-disclaimer',
  templateUrl: './disclaimer.component.html'
})
export class DisclaimerComponent {
  public readonly disclaimer$: Observable<string>;

  constructor() {}
}
