import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { publishLast, refCount } from 'rxjs/operators';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class TermsOfUseRequestService {
  private readonly SDPP_ROOT_API: string = environment.baseSdppApi;

  constructor(private readonly _http: HttpClient) {}

  public loadContent(): Observable<string> {
    return this._http.get<string>(`${this.SDPP_ROOT_API}/user/gettermsofuse`).pipe(publishLast(), refCount());
  }

  public sendAccept(): Observable<string> {
    return this._http.get<string>(`${this.SDPP_ROOT_API}/user/accepttermsofuse`);
  }
}
