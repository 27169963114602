import {
  ChildConfiguration,
  IConsoleAppender,
  IHttpAppender,
  LoggingConfiguration,
  LogLevel,
  LogLevelHelper
} from '@sdpp-web/logger';

import { IEnvironment } from './i-environment';

// app environment variables
const APP_CODE = `home`;
const TERMS_OF_USE_TARGET_URL_PARAM = `goto`;
const APP_LOG_LEVEL = LogLevelHelper.from('info');
const APP_LOGGER_ID = `sdpp-web-home`;

// sdpp web environment variables
const SDPP_WEB_BASE_API = `/api/sdpp`;
const SDPP_WEB_PUBLIC_BASE_API = `/public/api/sdpp`;
const SDPP_WEB_LOG_API = `/background/api/sdpp/audit/log/save`;
const SDPP_WEB_TELEMETRY_API = `/background/api/sdpp/audit/telemetry/save`;
const SDPP_WEB_TELEMETRY_LOGGER_ID = `Telemetry`;
const SDPP_WEB_TELEMETRY_LOG_LEVEL = LogLevelHelper.from('info');
const SDPP_WEB_SUPPORT_EMAIL_SMARTDERIVATIVES = `smartderivatives.support@bnpparibas.com`;
const SDPP_WEB_IMPERSONATION_ENABLED: string = `False`;
const SDPP_WEB_IMPERSONATION_HEADER = `BNPPUID_TEST`;

export const environment: IEnvironment = {
  termsOfUseTargetUrlParam: TERMS_OF_USE_TARGET_URL_PARAM,
  baseSdppApi: SDPP_WEB_BASE_API,
  baseSdppPublicApi: SDPP_WEB_PUBLIC_BASE_API,
  mailSupport: SDPP_WEB_SUPPORT_EMAIL_SMARTDERIVATIVES,
  loggingConfiguration: <LoggingConfiguration>{
    root: {
      appenders: [
        <IConsoleAppender>{
          id: 'RootConsoleAppender',
          level: LogLevel.ALL,
          type: 'CONSOLE'
        },
        <IHttpAppender>{
          applicationCode: APP_LOGGER_ID,
          id: 'RootHttpAppender',
          level: APP_LOG_LEVEL,
          type: 'HTTP',
          url: SDPP_WEB_LOG_API
        }
      ],
      level: LogLevel.ALL
    },
    children: [
      <ChildConfiguration>{
        appenders: [
          <IHttpAppender>{
            applicationCode: APP_CODE,
            id: `TelemetryHttpAppender`,
            level: SDPP_WEB_TELEMETRY_LOG_LEVEL,
            type: 'HTTP',
            url: SDPP_WEB_TELEMETRY_API
          }
        ],
        id: SDPP_WEB_TELEMETRY_LOGGER_ID,
        level: SDPP_WEB_TELEMETRY_LOG_LEVEL
      }
    ]
  },
  production: true,
  isDevEnvironment: false,
  telemetryLoggerId: SDPP_WEB_TELEMETRY_LOGGER_ID,
  userImpersonationConfiguration: {
    enabled: SDPP_WEB_IMPERSONATION_ENABLED === 'true',
    header: SDPP_WEB_IMPERSONATION_HEADER
  }
};
