import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '@environment';

import { TelemetryService } from '@sdpp-web/telemetry';
import { IUserImpersonationService, USER_IMPERSONATION_SERVICE } from '@sdpp-web/user';

import { SdppErrors } from './models/sdpp-error';

@Component({
  selector: 's-home-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  public readonly isDevEnvironment: boolean = environment.isDevEnvironment;

  public httpCode: number;
  public errorCode: string;
  public correlationId: string;
  public applicationUrl: string;
  public message: string;
  public portalUrl: string;
  public mailSupport: string;
  public impersonationUserUid: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly _telemetryService: TelemetryService,
    @Inject(USER_IMPERSONATION_SERVICE) private readonly _userImpersonationService: IUserImpersonationService
  ) {
    this.impersonationUserUid = this._userImpersonationService.getImpersonatedIdentity();
  }

  public ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.httpCode = +params['httpCode'];
      this.errorCode = params['errorCode'];
      this.correlationId = params['correlationId'];
      this.applicationUrl = params['applicationUrl'];
      this.portalUrl = params['portalUrl'];
      const sdppError = SdppErrors.find(
        e => e.errorCode.toLowerCase() === this.errorCode.toLowerCase() && e.httpCode === this.httpCode
      );
      this.message = sdppError ? sdppError.message : 'Unknown error';

      this._telemetryService.message({
        message: `user landed on sdpp web custom error page`,
        data: {
          httpCode: this.httpCode,
          errorCode: this.errorCode,
          correlationId: this.correlationId,
          applicationUrl: this.applicationUrl,
          portalUrl: this.portalUrl,
          message: this.message
        }
      });
    });
    this.mailSupport = environment.mailSupport;
  }

  public navigateToHomepage(): void {
    this._telemetryService.message({
      message: 'user navigates to homepage from sdpp web custom error page',
      data: null
    });

    this.router.navigateByUrl('');
  }

  public unsetImpersonation(): void {
    this._userImpersonationService.removeImpersonatedIdentity();
    setTimeout(() => {
      this.router.navigateByUrl('/');
    }, 100);
  }
}
